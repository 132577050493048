@use "../../mixin" as *;
@use "../../variable" as *;

.clientRev {
  display: flex;
  justify-content: center;
  background-color: #f2f2f2;

  .container {
    padding: 91px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media991 {
      padding: 34px 20px 54px 20px;
    }

    h2 {
      font-size: 40px;
      font-weight: 400;
      line-height: 1.2;
      text-transform: capitalize;
      margin-bottom: 15px;
      @include media991 {
        font-size: 22px;
        text-align: center;
      }
      span {
        font-weight: 700;
      }
    }

    .clientRating {
      margin-top: 25px;
      @include media991 {
        margin-top: 10px;
      }
    }

    .revContainer {
      margin-top: 59px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 21px;
      width: 100%;

      @include media991 {
        display: flex;
        margin-top: 20px;
        gap: 15px;
        flex-direction: column;
      }

      .revCard {
        max-width: 400px;
        width: 100%;
        background-color: #ffffff;
        border-radius: 15px;
        padding: 20px;
        height: fit-content;
        box-sizing: border-box;

        &-header {
          display: flex;
          display: flex;
          border-bottom: 1px solid #ded6d6;
          padding-bottom: 15px;

          .userImg {
            max-width: 40px;
            width: 100%;
            max-height: 40px;
            height: 100%;
            margin-right: 10px;
            border-radius: 50px;
          }
          .userNameImg {
            max-width: 40px;
            width: 100%;
            margin-right: 10px;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            background-color: $primary-color;
          }

          .right {
            h6,
            p {
              font-size: 14px;
            }

            .revNo {
              display: flex;
              gap: 20px;

              p {
                display: flex;

                .locationImg {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
        }

        &-body {
          margin-top: 15px;
          padding-bottom: 15px;

          .whenRated {
            display: flex;
            justify-content: space-between;

            p {
              font-size: 14px;
            }
          }

          .info {
            margin-top: 14px;

            h4,
            p {
              font-size: 14px;
            }

            p {
              margin-top: 10px;
            }

            h5 {
              font-size: 13px;
              margin-top: 10px;

              span {
                font-weight: 400;
              }
            }
          }
        }

        &-footer {
          display: none;
          justify-content: space-between;
          margin-top: 10px;

          .left {
            gap: 20px;
            display: flex;

            .useful,
            .share {
              display: flex;
              gap: 3px;
              align-items: center;

              p {
                font-size: 15px;
              }
            }
          }

          .right {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .loadMoreRevBtn {
      cursor: pointer;
      margin-top: 50px;
      text-transform: uppercase;
      max-width: 400px;
      width: 100%;
      padding: 15px 0px;
      border-radius: 50px;
      border: 1px solid;
      font-size: 18px;
      font-weight: 400;
      @include media991 {
        margin-top: 20px;
      }
    }
  }
}
