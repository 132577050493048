@use "../../mixin" as *;
@use "../../variable" as *;

.injuryClaims {
  background-color: #f2f2f2;
  justify-content: center;
  display: flex;
  text-align: center;

  @include media991 {
    padding: 0px 20px;
  }

  .container1500 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &-title {
    margin-top: 91px;
    font-size: 40px;
    font-weight: 400;
    line-height: 1.2;

    @include media991 {
      margin-top: 40px;
      font-size: 22px;
    }

    span {
      font-weight: 800;
    }
  }

  &-subTitle {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.4;
    margin-top: 23px;

    @include media991 {
      margin-top: 18px;
      font-size: 16px;
    }
  }

  &-types {
    margin-top: 53px;
    margin-bottom: 128px;
    display: flex;
    gap: 15px;

    @include media991 {
      flex-direction: column;
      margin-top: 10px;
      margin-bottom: 40px;
    }

    .type {
      max-width: 300px;
      width: 100%;
      background-color: #ffffff;
      border-radius: 25px;

      @include media991 {
        max-width: 100%;
      }

      .tp {
        padding: 20px 5px;

        @include media991 {
          text-align: left;
          box-sizing: border-box;
          display: flex;
          border-radius: 20px;
          padding: 15px;
        }

        .img {
          max-width: 100px;
          width: 100%;
          max-height: 100px;
          max-height: 100%;

          @include media991 {
            max-width: 70px;
            max-height: 70px;
            margin-right: 15px;
          }
        }

        .info {
          margin-top: 14px;
          @include media991() {
            margin-top: 0;
          }
          h4 {
            font-size: 24px;
            font-weight: 700;
            line-height: 1.2;
            text-transform: capitalize;
            @include media991 {
              font-size: 18px;
            }
          }

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 1.2;
            padding: 0px 20px;
            margin-top: 15px;

            @include media991 {
              font-size: 14px;
              margin-top: 3px;
              padding: 0px;
            }
          }
        }
      }
    }
  }
}
