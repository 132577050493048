@use "../../mixin" as *;
@use "../../variable" as *;

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  .container1500 {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &-info {
    max-width: 1283.46px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 23px 123px 24px 123px;

    @include media991 {
      padding: 27px 42px 41px 43px;
      flex-direction: column;
    }

    .logo {
      max-width: 202px;
      width: 100%;
      max-height: 66px;
      height: 100%;

      @include media991 {
        max-width: 171.39px;
        height: 56px;
      }
    }

    .policies {
      display: flex;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.2;
      @include media991() {
        margin-top: 20px;
      }
      @include media575 {
        display: none;
        font-size: 14px;
        text-align: center;
        margin-top: 20px;
      }
      p {
        word-wrap: break-word;
      }
      p a {
        text-decoration: none;
        color: $black-color;
      }
      .span {
        display: block;
      }
      p a:hover {
        text-decoration: underline;
      }
      p:not(:last-child)::after {
        margin: 0px 10px;
        content: "-";
        display: inline-block;

        @include media991 {
          margin: 0px 7px;
        }
      }
    }
  }
  &-mob {
    display: none;
  }
}
@include media575-min() {
  .footer-info-mob {
    display: none;
  }
}
@include media575() {
  .footer-info-mob {
    text-align: center;
    padding-bottom: 41px;
  }
  .mob-link {
    display: inline-block;
    text-align: center;
  }
  span a {
    text-decoration: none;
    color: $black-color;
    font-weight: 500;
  }
}
