@use "../../mixin" as *;
@use "../../variable" as *;

.speak {
  background-color: #00554c;
  color: #ffffff;
  display: flex;
  justify-content: center;

  .container1500 {
    padding: 0px 130px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media1300 {
      padding: 34px 20px;
    }

    @include media1500 {
      width: 100%;
    }

    @include media991 {
      flex-direction: column-reverse;
    }
  }

  &-left {
    max-width: 444px;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;

    @include media991 {
      text-align: center;
    }

    .chatDescView {
      max-width: 80px;
      width: 100%;
      max-height: 80px;
      height: 100%;

      @include media991 {
        display: none;
      }
    }

    h2 {
      font-size: 40px;
      font-weight: 700;
      line-height: 1.2;
      margin-bottom: 18px;
      margin-top: 20px;

      @include media991 {
        font-size: 22px;
        margin-top: 8px;
        margin-bottom: 20px;
      }

      span {
        font-weight: 400;
      }
    }

    .desc {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.4;
      margin-bottom: 19px;

      @include media991 {
        font-size: 16px;
        margin-top: 0px;
        margin-bottom: 20px;
      }
    }
  }

  &-right {
    margin-left: 63px;
    @include media991 {
      margin-left: 0px;
    }

    .comRightImg {
      max-width: 776px;
      object-fit: cover;
      width: 100%;
      max-height: 545px;
      height: 100%;
      border-radius: 30px;
      margin-bottom: -5px;
      @include media991() {
        margin-bottom: 0;
      }
    }
  }
  .deskView {
    @include media991 {
      display: none;
    }
  }

  .mbView {
    @include media992-min {
      display: none;
    }
  }
}
