@use "../../mixin" as *;
@use "../../variable" as *;

.claimBtn {
  background-color: $button-color;
  border-radius: 100px;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
  border: none;
  max-width: 388px;
  width: 100%;
  padding: 18px 0px;
  line-height: 1.4;
  cursor: pointer;

  @include media991 {
    font-size: 18px;
    min-width: 100%;
    padding: 14px 0;
    line-height: 1.2;
  }

  .arrow {
    margin-left: 12.6px;
    max-width: 23.73px;
    width: 100%;

    @include media991 {
      max-width: 20.55px;
    }
  }
}
