$primary-font: "Montserrat";
$secondary-font: "Inter";
$light-color: #ffffff;
$black-color: #000000;
$announcment-bar-color: #3daaef;
$primary-color: #1b75bb;
$secondary-color: #a0d5f6;
$strip-color: #08a972;
$button-color: #ff6608;
$background-color: #f2f2f2;
$border-color: #0000002e;
$secondary-font-color: #9137ea;

//old colors
// $primary-font: "Montserrat";
// $secondary-font: "Inter";
// $light-color: #ffffff;
// $black-color: #000000;
// $announcment-bar-color: #1b75bb;
// $primary-color: #3daaef;
// $secondary-color: #a0d5f6;
// $strip-color: #08a972;
// $button-color: #ff7d46;
// $background-color: #f2f2f2;
// $border-color: #0000002e;
// $secondary-font-color: #9137ea;
