@use "../../mixin" as *;
@use "../../variable" as *;

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .container1500 {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &-info {
    max-width: 1283.46px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 25px 20px;

    @include media991 {
      padding: 20px 15px;
    }

    .logo {
      max-width: 202px;
      width: 100%;
      max-height: 66px;
      height: 100%;

      @include media991 {
        max-width: 121px;
        max-height: 40px;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .mcaFree {
        max-width: 124.87px;
        min-height: 45px;
        width: 100%;
        height: 100%;
        margin-right: 13px;

        @include media991 {
          max-width: 58.27px;
          min-height: 21px;
        }
      }

      .globalSign {
        max-width: 144.58px;
        min-height: 55px;
        width: 100%;
        height: 100%;

        @include media991 {
          max-width: 70.98px;
          min-height: 27px;
        }
      }
    }
  }
}

.announcementBar {
  @include media992-min() {
    display: none;
  }
  background-color: $announcment-bar-color;
  color: #ffffff;
  padding: 7px 15px;

  @include media991 {
    position: sticky;
    top: 0;
    z-index: 999;
  }

  .container1500 {
    width: 100%;
  }

  &-info {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    img {
      width: 30px;
      height: 30px;
      margin-right: 5.7px;

      @include media991 {
        width: 20.45px;
        height: 22.49px;
      }
    }

    p {
      overflow: hidden;
      text-transform: capitalize;

      @include media991 {
        font-size: 14px;
        text-align: center;
      }
    }
  }
}

.strip_bar {
  font-family: $secondary-font;
  width: 100%;
  background-color: #022f5b;
  padding: 12px 0 14px 0;
  overflow: hidden;
  color: #ffffff;
  font-weight: 500;

  @include media991() {
    min-width: 1200px;
    padding: 7px 0;

    img {
      max-width: 20px;
      height: auto;
    }
  }

  .wrapper {
    width: 100%;
    margin-inline: auto;
    display: flex;
    align-items: center;
  }

  &-one {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-left: 1px solid $light-color;

    p {
      @include media991 {
        font-size: 14px;
      }
    }

    @include media575() {
      justify-content: space-around;
      width: 30%;
    }

    div {
      display: flex;
      align-items: center;
    }

    figure {
      margin: 0 11px 0 0px;
    }

    &:first-child {
      justify-content: flex-start;
      border: none;
      width: 70%;

      @include media1350() {
        justify-content: center;
      }

      @include media991() {
        justify-content: center;
        border-left: 1px solid $light-color;
      }

      @include media575() {
        width: 30%;
      }
    }

    &:last-child {
      border-left: 1px solid $light-color;
      width: 70%;
      justify-content: flex-end;

      @include media1350() {
        justify-content: center;
      }

      @include media991() {
        border-left: 1px solid $light-color;
        justify-content: center;
      }

      @include media575() {
        width: 30%;
      }
    }
  }
}
