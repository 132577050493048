@use "../../mixin.scss" as *;
@use "../../variable.scss" as *;

.faq {
  padding: 92px 0 100px;
  box-sizing: border-box;
  @include media991() {
    padding: 35px 0 40px;
  }
  .container1286 {
    box-sizing: border-box;
  }

  .faq-accordion {
    box-sizing: border-box;
    text-align: center;
    max-width: 740px;
    margin: 0 auto;
    .faq-accordion-details {
      h2 {
        margin-bottom: 0.5em;
        font-weight: 500;
        font-size: 40px;
        @include media575() {
          font-size: 22px;
        }
      }
      p {
        font-size: 20px;
        margin-bottom: 53px;
        @include media575() {
          font-size: 16px;
        }
        @include media991() {
          margin-bottom: 23px;
        }
      }
    }

    .accordion-item {
      border: 1px solid $border-color;
      border-radius: 15px;
      margin: 20px 0;
      overflow: hidden;
      transition: background 0.3s ease;
      .accordion-label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        cursor: pointer;
        padding: 9px 18px;
        transition: background-color 0.3s;
        .accordion-question {
          font-size: 18px;
          max-width: 80%;
          text-align: left;
          @include media991() {
            font-size: 16px;
          }
        }
        .icon {
          position: relative;
          width: 35px;
          height: 35px;
          background-color: $black-color;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .bar-one {
            width: 14px;
            height: 4px;
            background-color: $light-color;
            border-radius: 10px;
          }
          .bar-two {
            position: absolute;
            width: 14px;
            height: 4px;
            background-color: $light-color;
            border-radius: 10px;
            top: 50%;
            left: 50%;  // Add this to center horizontally
            transform: translate(-50%, -50%) rotate(-90deg); 
            transition: 0.3s;
          }
        }
      }

      .accordion-content {
        max-height: 0;
        overflow: hidden;
        background: $background-color;
        font-size: 0.95em;
        transition: max-height 0.5s ease, padding 0.5s ease;
        text-align: left;
        padding: 0 18px;
        p {
          font-size: 16px;
          margin-top: 8px;
        }
        .tnc {
          font-size: 14px;
        }
      }

      &.active {
        .accordion-label {
          background: $background-color;
          font-weight: bold;
        }

        .accordion-content {
          transition: padding 0.5s;
        }

        .icon {
          .bar-two {
            transform: rotate(0deg) translate(-50%, -50%); // Rotate the icon when active
          }
        }
      }
    }
  }
}
