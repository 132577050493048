@use "../../mixin.scss" as *;
@use "../../variable.scss" as *;

.b_body {
  background-color: $background-color;
}
.banner {
  width: 100%;
  padding: 70px 0 0px 0;
  box-sizing: border-box;
  @include media1350() {
    padding: 20px 0 0 0;
  }
  @include media991() {
    padding: 25px 0 0 0;
  }
  &__ring {
    position: absolute;
    user-select: none;
    pointer-events: none;
    left: 0;
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    @include media1350() {
      padding: 0 20px;
    }
    @include media991() {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    @include media575() {
      padding: 0 0px;
    }
  }

  &__details {
    width: 100%;
    margin: auto 0;

    &-rating {
      display: flex;
      align-items: center;
      z-index: 5;
      gap: 10px;
      @include media1350() {
        font-size: 14px;
      }

      @include media991() {
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      @include media475() {
        font-size: 2.9vw;
        img {
          max-width: 60px;
        }
      }
      @include media400() {
        img {
          max-width: 62px;
        }
      }
    }
    &-header {
      h1 {
        font-size: 40px;
        font-weight: 400;
        margin-top: 25px;
        line-height: 54px;
        .banner-different {
          color: $primary-color;
          font-weight: 700;

          @include media991() {
            font-weight: 800;
          }
        }
        .break {
          display: block;
        }
        @include media1350() {
          font-size: 2vw;
          line-height: normal;
        }
        @include media991() {
          text-align: center;
          font-size: 30px;
          line-height: normal;
        }
        @include media475() {
          font-size: 22px;
        }
      }
    }
    &-list {
      list-style: none;
      margin: 25px 0;
      @include media991() {
        display: none;
      }
      li {
        display: flex;
        margin: 15px 0;
        @include media991() {
          justify-content: center;
        }
        figure {
          max-width: 18px;
          width: 100%;
          height: auto;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .listPoints {
          font-size: 18px;
        }
      }
    }

    &-review {
      display: flex;
      max-width: 556px;
      background-color: $light-color;
      border-radius: 10px;
      margin-top: 25px;
      box-sizing: border-box;
      box-shadow: 0 0 3px #000;
      padding: 10px;
      @include media991() {
        width: 100%;
        margin-bottom: 31px;
      }
      @include media575() {
        padding: 15px;
      }
     
      .review-details {
        margin-left: 21px;
        width: 100%;
        @include media575() {
          margin-left: 10px;
        }
        p {
          @include media575() {
            font-size: 14px;
          }
        }
        span {
          display: flex;
          align-items: center;
          @include media575() {
            font-size: 14px;
          }
          @include media475() {
            font-size: 10px;
          }
          img {
            width: 14px;
            height: 14px;
            margin: 0 5px 0 10px;
          }
        }
        .uName {
          font-size: 12px;
        }
        figure {
          max-width: 75px;
          height: auto;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      @include media991() {
        .review-image img {
          max-width: 50px;
          height: 50px;
        }
      }
    }
  }
  &__card {
    width: 80%;
    text-align: center;
    padding: 37px 63px;
    border-radius: 30px;
    background: $light-color;
    margin-left: 7px;
    box-sizing: border-box;
    @include media991() {
      width: 450px;
      padding: 25px 13px;
      margin: 15px 0;
    }
    @include media575() {
      width: 400px;
    }
    @include media475() {
      width: 100%;
    }
    h2 {
      font-size: 39px;
      text-transform: capitalize;
      @include media1350() {
        font-size: 30px;
      }
      @include media991() {
        font-size: 30px;
      }
      @include media575() {
        font-size: 20px;
      }
    }
    p {
      margin-top: 10px;
      font-size: 20px;
      @include media575() {
        font-size: 14px;
      }
    }
    &-buttons {
      margin-top: 20px;

      .button {
        display: flex;
        justify-content: center;

        button {
          max-width: 478px;
          width: 100%;
          padding: 20px 30px;
          margin: 10px 0;
          background-color: $light-color;
          border-radius: 15px;
          text-decoration: none;
          color: $black-color;
          font-weight: 600;
          font-size: 22px;
          font-family: $primary-font;
          border: 1px solid $border-color;
          pointer-events: all;
          cursor: pointer;
          transition: 0.2s;
          @include media1350() {
            font-size: 18px;
          }
          @include media575() {
            font-size: 16px;
            margin: 6px 0;
            padding: 15px 15px;
          }
          &:hover {
            background-color: rgb(228, 228, 228);
          }
          &.active {
            background-color: $strip-color; // Active background color
            color: $light-color; // Adjust text color if needed
          }
          img {
            margin: 0 0 0 9px;
          }
        }
      }
    }
  }
  &__description {
    max-width: 945px;
    text-align: center;
    margin: 0 auto;
    padding: 0 0 67px 0;
    @include media991() {
      display: none;
    }
    h3 {
      margin: 41px 0 14px;
      font-size: 25px;
    }
  }
  .safe_secure {
    font-size: 16px;
    @include media575() {
      font-size: 12px;
    }
  }
}
@include media992-min() {
  .mob-show {
    display: none;
  }
}
@include media991() {
  .desk-show {
    display: none;
  }
}
.pad-lock {
  margin: 0 6.5px 0 0;
}
