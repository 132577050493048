@use "../../mixin" as *;
@use "../../variable" as *;

.form {
  display: flex;
  justify-content: center;
  padding: 50px 0px 60px 0px;
  @include media991() {
    padding: 25px 0 30px 0;
  }
  .container989 {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0px 20px;
  }
  @include media991() {
    max-width: 460px;
    margin: 0 auto;
  }
  &-rating {
    display: flex;
    gap: 10px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
    align-items: center;

    @include media991 {
      font-size: 12px;
      gap: 2px;
    }

    span {
      font-weight: 400;

      img {
        max-width: 18.07px;
        width: 100%;

        @include media991 {
          max-width: 14px;
          max-height: 14px;
          height: 100%;
        }
      }

      .star {
        max-width: 18.07px;
        width: 100%;

        @include media991 {
          max-width: 15px;
          max-height: 15px;
          height: 100%;
        }
      }
    }
  }

  &-title {
    font-size: 40px;
    font-weight: 800;
    line-height: 1.4;
    color: #1b75bb;
    max-width: 805px;
    width: 100%;
    margin-top: 25px;

    @include media991 {
      margin-top: 15px;
      font-size: 22px;
    }

    .s1 {
      font-weight: 400;
      color: #000000;
    }

    .s2 {
      font-weight: 800;
      color: #000000;
    }
  }

  &-que1 {
    width: 100%;
    background-color: #f2f2f2;
    border-radius: 30px;
    margin-top: 60px;
    padding: 46px 10px 60px 10px;
    box-sizing: border-box;
    position: relative;
    z-index: 2;

    .ring {
      position: absolute;
      left: 0;
      z-index: -1;

      @include media991() {
        display: none;
      }
    }

    @include media991 {
      margin-top: 15px;
      padding: 15px;
    }

    h3 {
      font-size: 40px;
      font-weight: 700;
      line-height: 1.4;
      margin-bottom: 14px;
      @include media991 {
        font-size: 20px;
        margin-bottom: 6px;
      }
    }

    p {
      font-size: 20px;
      font-weight: 400;
      line-height: 1.4;

      span {
        font-weight: 700;
        color: #1b75bb;
        text-decoration: underline;
      }

      @include media991 {
        font-size: 14px;
      }
    }

    .ansBtn {
      display: flex;
      justify-content: center;
      gap: 15px;
      margin-top: 43px;
      align-items: center;

      @include media991 {
        margin-top: 13px;
        flex-direction: column;
      }

      .btn {
        cursor: pointer;
        padding: 21.1px 0px;
        max-width: 428px;
        width: 100%;
        border-radius: 15px;
        background-color: #ffffff;
        border: 1px solid #0000002e;
        font-size: 22px;
        font-weight: 600;
        line-height: 1.4;

        @include media991 {
          max-width: 100%;
          font-size: 16px;
        }

        &:hover {
          background-color: rgb(228, 228, 228);
        }

        @include media991 {
          padding: 8px 0px;
        }
      }
    }

    .continueBtn {
      width: 100%;
      margin-top: 20px;

      button {
        max-width: 613px;
        width: 100%;
        background-color: $button-color;
        border-radius: 100px;
        text-transform: uppercase;
        font-size: 25px;
        font-weight: 700;
        color: #ffffff;
        border: none;
        padding: 20px 0px;
        line-height: 1.4;
        cursor: pointer;

        @include media991 {
          font-size: 18px;
          padding: 10px 0px;
        }

        .arrow {
          margin-left: 12.6px;
          max-width: 23.73px;
          width: 100%;

          @include media991 {
            max-width: 20.55px;
          }
        }
      }
      .disable {
        display: none;
      }
      button:focus-visible {
        outline: 1px solid #c15528;
      }
    }

    .lockInfo {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      margin-top: 19.5px;
      padding: 0px 52px;
      div {
        display: flex;
        align-items: flex-start;
      }
      @include media991 {
        padding: 0px;
        margin-top: 10px;
      }

      img {
        max-width: 13.72px;
        width: 100%;
        max-height: 18px;
        height: 100%;
        margin-right: 15px;

        @include media991 {
          margin-right: 8px;
          width: 10.67px;
          height: 14px;
        }
      }

      p {
        text-align: justify;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.14;
        margin-left: 5px;
        @include media991 {
          font-size: 12px;
        }

        span button {
          background-color: none;
          border: none;
          font-weight: 600;
          padding: 0;
          cursor: pointer;
          color: #880db9;
          text-decoration: underline;
          font-size: 16px;
        }
      }
    }

    .email {
      padding: 0px 59px;

      @include media991 {
        padding: 0px;
      }
    }

    .fullName,
    .email,
    .phoneNumber {
      margin-top: 38px;
      display: flex;
      gap: 15px;
      justify-content: center;
      text-align: left;
      align-items: center;

      @include media991 {
        margin-top: 13px;
        flex-direction: column;
        gap: 4px;
      }

      &-col {
        max-width: 428px;
        width: 100%;
        @include media991 {
          max-width: 100%;
        }
      }

      .column {
        gap: 12px;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        @include media991 {
          gap: 6px;
        }

        label {
          font-size: 22px;
          font-weight: 500;
          line-height: 1.4;

          @include media991 {
            font-size: 16px;
          }
        }

        input[type="text"],
        input[type="tel"],
        input[type="number"] {
          padding: 21px;
          border-radius: 15px;
          border: 1px solid #0000002e;
          font-weight: 400;
          font-size: 22px;
          line-height: 1.4;
          color: #7c7c7c;

          @include media991 {
            flex-direction: column;
            padding: 10px;
            font-size: 16px;
          }
        }
        /* For Chrome, Safari, Edge, and Opera */
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          display: none;
          -webkit-appearance: none;
        }

        /* For Firefox */
        input[type="number"] {
          appearance: textfield;
          -moz-appearance: textfield;
        }

        input[type="tel"] {
          width: 100%;
          padding-left: 61px;

          @include media991 {
            padding-left: 51px;
          }
        }

        input[type="tel"]::placeholder {
          position: absolute;
          left: 7%;
          width: 100%;

          @include media991 {
            left: 18%;
          }
        }
        .resend {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          @include media991() {
            justify-content: center;
          }
          .resend-otp {
            display: block;
            background: none;
            border: none;
            cursor: pointer;
            font-size: 16px;
            margin: 5px 0;
            &:hover .underline {
              text-decoration: underline;
            }
          }
          .wrong-number {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            @include media991() {
              justify-content: center;
            }
            &:hover {
              text-decoration: underline;
            }
          }
        }

        .iti.iti--allow-dropdown {
          width: 100%;
        }

        input:focus-visible {
          outline: 1px solid #0000002e;
        }
      }
    }

    .phoneNumber {
      padding: 0px 52px;

      @include media991 {
        .iti--container {
          position: absolute;
          top: 712px;
          left: 7px;
          z-index: 1060;
          width: 100%;
          object-fit: cover;
          background: white;
        }
      }

      .iti__selected-flag {
        padding: 0px 0px 0px 4px;
        position: absolute;
        background-repeat: no-repeat;
        top: 32%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media991 {
          top: 20%;
        }
      }

      .iti__arrow {
        margin-left: 0px;
        display: none;
      }

      .iti__selected-flag {
        cursor: pointer;
      }

      .iti__flag {
        clip-path: circle(40%);
      }

      .iti__country-list {
        display: none;
      }

      .form-que1 .phoneNumber .column .iti__selected-flag {
        position: relative;
        z-index: 2;
      }

      @include media991 {
        padding: 0px;
      }
    }
  }

  &-rev {
    width: 100%;
    display: flex;
    background-color: #f2f2f2;
    color: #111111;
    border-radius: 10px;
    margin-top: 23px;

    .container {
      display: flex;
      padding: 20px;

      @include media991 {
        padding: 15px 7px 15px 15px;
      }
    }

    @include media991() {
      margin-top: 15px;
    }

    .review-details {
      text-align: left;
      margin-left: 21px;

      @include media991 {
        font-size: 14px;
        margin-left: 10px;
      }

      p {
        margin: 6px 0 17px;

        span {
          font-weight: 700;
        }
      }

      .user {
        display: flex;
        font-size: 12px;

        @include media991 {
          font-size: 10px;
          flex-wrap: wrap;
        }

        .userName {
          margin-right: 10px;
          font-size: 14px;
          text-transform: capitalize;
          @include media991 {
            margin-right: 5px;
            margin-bottom: 5px;
            font-size: 14px;
          }
        }

        .verified {
          display: flex;
          align-items: center;

          .check {
            margin-right: 5.5px;

            @include media991 {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }

    .review-image img {
      width: 91px;
      height: 91px;
      clip-path: circle(50%);
    }

    @include media991() {
      .review-image img {
        width: 50px;
        height: 50px;
      }
    }
  }

  &-progressBar {
    max-width: 900px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
    padding: 10px 0px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%231B75BBFF' stroke-width='3' stroke-dasharray='8%2c16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 30px;

    .number {
      max-width: 45px;
      width: 100%;
      height: 45px;
      border: 1.5px solid #00000059;
      color: #00000059;
      border-radius: 100%;
      font-family: $primary-font;
      font-size: 22px;
      font-weight: 400;
      line-height: 1.2;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      @include media991 {
        max-width: 28px;
        max-height: 28px;
        font-size: 16px;
      }
    }

    .arrow {
      max-width: 32.57px;
      width: 100%;
      max-height: 12px;
      height: 100%;

      @include media991 {
        display: none;
      }
    }
  }
}
.disableClass {
  background-color: $background-color !important;
  color: $border-color;
  cursor: not-allowed !important;
}
