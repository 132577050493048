@use "../../mixin" as *;
@use "../../variable" as *;

.benifits {
    background-color: $announcment-bar-color;
    color: white;
    padding: 40px 0px;

    @include media991 {
        padding: 5px 31px;
        padding-bottom: 25px;
    }

    .container1500 {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media991 {
            flex-direction: column;
        }
    }

    .mainType {
        display: flex;
        align-items: center;

        .type {
            display: flex;
            max-width: 354px;
            width: 100%;
            padding: 0px 46px;
            justify-content: space-between;

            @include media991 {
                margin-top: 25px;
                padding: 0px;
            }


            .left {
                display: flex;
                margin-right: 15px;

                @include media991 {
                    align-items: center;
                }

                img {
                    max-width: 70px;
                    width: 100%;
                    max-height: 70px;
                    height: 100%;

                    @include media991 {
                        max-width: 50px;
                        max-height: 50px;
                    }
                }
            }

            .right {
                display: flex;
                max-width: 269px;
                width: 100%;

                @include media991 {
                    max-width: 237px;
                    width: 100%;
                }

                .data {
                    h6 {
                        font-size: 25px;
                        font-weight: 600;
                        line-height: 1;
                        font-family: $secondary-font;

                        @include media991 {
                            font-size: 18px;
                        }
                    }

                    p {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 1.4;
                        margin-top: 5px;

                        @include media991 {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .border {
            border-right: 1px solid;
            height: 52px;

            @include media991 {
                display: none;
            }

            &.hidden {
                display: none;
            }
        }
    }


}