@use "../../mixin" as *;
@use "../../variable" as *;

.review {
    display: flex;
    max-width: 462px;
    background-color: #ffffff;
    color: #111111;
    border-radius: 10px;
    margin-top: 25px;

    .container {
        display: flex;
        padding: 20px;
    }

    @include media991() {
        margin-top: 15px;
    }

    .review-image {
        img {
            max-width: 81px;
            max-height: 81px;
            clip-path: circle(50%);
        }
    }

    .review-details {
        margin-left: 21px;
        max-width: 404px;
        width: 100%;

        @include media991 {
            font-size: 14px;
        }

        p {
            margin: 6px 0 17px;
        }

        span {
            display: flex;
            font-size: 14px;

            .RevVerifiedCus {
                font-size: 12px;
            }

            .userName {
                margin-right: 10px;
            }

            .check {
                margin-right: 5.5px;
            }
        }
    }

    @include media991() {
        .review-image img {
            max-width: 50px;
            height: 50px;
        }
    }
}