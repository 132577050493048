@mixin media400 {
  @media only screen and (max-width: 400px) {
    @content;
  }
}
@mixin media475 {
  @media only screen and (max-width: 475px) {
    @content;
  }
}
@mixin media475-min {
  @media only screen and (min-width: 475px) {
    @content;
  }
}
@mixin media575 {
  @media only screen and (max-width: 575px) {
    @content;
  }
}
@mixin media575-min {
  @media only screen and (min-width: 575px) {
    @content;
  }
}
@mixin media991 {
  @media only screen and (max-width: 991px) {
    @content;
  }
}

@mixin media992-min {
  @media only screen and (min-width: 992px) {
    @content;
  }
}
@mixin media1350 {
  @media only screen and (max-width: 1350px) {
    @content;
  }
}
@mixin media1350-min {
  @media only screen and (min-width: 1350px) {
    @content;
  }
}

@mixin media1300 {
  @media only screen and (max-width: 1300px) {
    @content;
  }
}

@mixin media1500 {
  @media only screen and (max-width: 1500px) {
    @content;
  }
}
@mixin media1500-min {
  @media only screen and (min-width: 1500px) {
    @content;
  }
}

@mixin media1500-min {
  @media only screen and (min-width: 1500px) {
    @content;
  }
}

@include media1500-min() {
  .container1500 {
    width: 1500px;
  }

  .container989 {
    width: 989px;
  }
}
.container1286 {
  max-width: 1286px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}
