@use "../../mixin" as *;
@use "../../variable" as *;

.compensation {
  background: linear-gradient(120.58deg, #1b75bb 10.52%, #08a972 68.57%);
  color: #ffffff;
  display: flex;
  justify-content: center;

  @include media991 {
    padding: 20px 0;
    background: linear-gradient(180deg, #1b75bb 43.2%, #08a972 100%);
  }

  .container1500 {
    padding: 0px 131px 0px 127px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;

    @include media1500 {
      width: 100%;
      padding: 0 0 0 20px;
    }

    @include media991 {
      flex-direction: column-reverse;
      align-items: center;
      padding: 0 20px;
    }
  }

  &-left {
    max-width: 509px;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 100px 0;
    @include media1500() {
      padding: 20px 0;
    }
    @include media991 {
      margin-top: -27px;
      margin-left: 40px;
    }

    @include media575() {
      margin-left: 0;
    }

    .compensationRating {
      gap: 10px !important;
    }

    .subTitle {
      text-transform: capitalize;
      font-size: 30px;
      font-weight: 400;
      line-height: 1.2;
      margin: 25px 0;

      @include media991 {
        font-size: 16px;
        margin: 15px 0;
      }
    }

    h2 {
      font-size: 40px;
      font-weight: 700;
      line-height: 1.2;
      margin: 0px 0 25px 0;
      span {
        font-weight: 400;
        display: block;
        @include media991() {
          display: inline;
        }
      }
      @include media991 {
        font-size: 22px;
        margin: 0 0 15px 0;
      }
    }

    .desc {
      font-size: 20px;
      font-weight: 400;
      line-height: 1.4;
      margin-bottom: 20px;

      @include media991 {
        font-size: 16px;
        margin-top: 8px;
        margin-bottom: 9px;
      }
    }
  }

  .banner__details-rating {
    justify-content: flex-start;
  }

  .claimBtn {
    @include media991() {
      min-width: 90%;
    }
  }

  &-right {
    max-width: 687px;
    width: 100%;
    margin-left: 82px;
    position: relative;
    @include media991 {
      margin-left: 0px;
      display: flex;
      justify-content: center;
    }

    .comRightImg {
      position: relative;
      bottom: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;

      @include media991() {
        width: auto;
        height: auto;
      }
    }
  }

  .deskView {
    @include media991 {
      display: none;
    }
  }

  .mbView {
    @include media992-min {
      display: none;
    }
  }
}
