@use "../../mixin.scss" as *;
@use "../../variable.scss" as *;

.sorry {
  padding: 100px 0;
  text-align: center;
  box-sizing: border-box;
  @include media575() {
    padding: 50px 0;
  }
  .container1286 {
    box-sizing: border-box;
  }
  h1 {
    font-size: 42px;
    text-transform: uppercase;
    @include media991() {
      font-size: 4vw;
    }
    @include media575() {
      font-size: 24px;
    }
  }
  h2 {
    font-size: 38px;
    text-transform: capitalize;
    margin-top: 10px;
    @include media991() {
      font-size: 3.5vw;
    }
    @include media575() {
      font-size: 20px;
    }
    span {
      display: block;
      @include media991() {
        display: inline;
      }
    }
  }
  &-btns {
    margin-top: 60px;

    a {
      display: inline-block;
      text-decoration: none;
      background: $button-color;
      color: $light-color;
      padding: 15px 30px;
      font-size: 24px;
      border-radius: 10px;
      font-weight: 600;
      transition: 0.3s;
      @include media991() {
        font-size: 3vw;
      }
      &:hover {
        background-color: #a74000;
      }
    }
  }
}
