@use "../../mixin" as *;
@use "../../variable" as *;

.success {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 55px 0 80px;
  @include media991() {
    padding: 25px 0 0px 0;
  }
  &__info {
    width: 100%;
    display: flex;
    justify-content: center;

    .container {
      margin: 0px 20px;
      max-width: 1106px;
      width: 100%;
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    .title {
      font-size: 40px;
      line-height: 1.4;
      font-weight: 400;
      margin-top: 19px;
      text-align: center;
      text-transform: capitalize;
      @include media991 {
        font-size: 22px;
        margin-top: 12px;
      }

      .sp1 {
        color: #1b75bb;
        font-weight: 700;
      }

      .sp2 {
        font-weight: 700;
      }
    }

    .note {
      font-size: 20px;
      font-style: italic;
      font-weight: 600;
      line-height: 1;
      color: #e90000;
      margin-top: 20px;
      text-align: center;
      text-transform: capitalize;
      @include media991 {
        font-size: 16px;
        line-height: 1.4;
        margin-top: 9px;
      }
    }
  }

  &__timer {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #f2f2f2;
    padding: 80px 0px;
    margin-top: 53px;
    position: relative;
    @include media991 {
      width: 100%;
      padding: 37px 0px;
      margin-top: 17px;
      padding: 37px 35px;
      box-sizing: border-box;
    }

    .container {
      max-width: 885px;
      width: 100%;
      text-align: center;

      .clock {
        max-width: 119.7px;
        width: 100%;
        max-height: 139.35px;
        height: 100%;

        @include media991 {
          max-width: 63px;
          max-height: 74px;
        }
      }

      .contown {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 23px;
        margin-top: 23px;

        @include media991 {
          gap: 13px;
        }

        .time {
          background-color: #000000;
          color: #ffffff;
          max-width: 100px;
          width: 100%;
          height: 100px;
          border-radius: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          @include media991 {
            max-width: 80px;
            height: 80px;
          }

          .digit {
            font-size: 40px;
            font-weight: 600;
            line-height: 1.3;

            @include media991 {
              font-size: 30px;
            }
          }

          .unit {
            font-size: 20px;
            font-weight: 500;
            line-height: 1.3;

            @include media991 {
              font-size: 16px;
            }
          }
        }

        .colon {
          font-size: 55px;
          font-weight: 700;
          line-height: 71.5px;

          @include media991 {
            font-size: 40px;
          }
        }
      }

      .thanks {
        margin-top: 39px;
        text-transform: uppercase;
        font-size: 55px;
        font-weight: 700;
        line-height: 1.3;

        @include media991 {
          font-size: 25px;
          margin-top: 27px;
        }
      }

      .call {
        margin-top: 10px;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.3;

        @include media991 {
          font-size: 16px;
          margin-top: 14px;
        }

        span {
          font-weight: 800;
          a {
            color: #1b75bb;
            text-decoration: none;
          }
        }
      }
    }
  }
  .success-ring {
    position: absolute;
    left: 0;
    bottom: 0;
    @include media991() {
      display: none;
    }
  }
  &__specifications {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 43px;
    margin-top: 60px;

    @include media991 {
      margin-top: 40px;
    }

    .container {
      max-width: 1240px;
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 35px;
      padding: 0px 20px;

      @include media991 {
        flex-direction: column;
        align-items: center;
      }

      .type {
        max-width: 390px;
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;

        img {
          max-width: 95px;
          max-height: 95px;

          @include media991 {
            max-width: 85px;
            max-height: 85px;
          }
        }

        h5 {
          text-transform: capitalize;
          font-size: 25px;
          font-weight: 600;
          line-height: 1.3;
          margin-top: 15px;

          @include media991 {
            font-size: 18px;
          }
        }

        p {
          font-size: 18px;
          font-weight: 400;
          line-height: 1.6;
          margin-top: 15px;
          @include media991 {
            font-size: 14px;
          }
        }
      }
    }
  }
}
