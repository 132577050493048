@use "../../mixin" as *;
@use "../../variable" as *;

.disclaimer {
  padding: 0px 130px;
  padding-top: 52px;
  padding-bottom: 54px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  @include media991 {
    padding: 15px 20px;
    margin-top: 5px;
    margin-bottom: 0px;
  }

  h2 {
    line-height: 1.4;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
    @include media991 {
      font-size: 20px;
    }
  }

  .desc {
    margin-top: -13px;

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.4;
      margin-bottom: 10px;
      @include media991 {
        font-size: 14px;
      }

      span {
        font-weight: 600;
      }
    }
    p a {
      color: $black-color;
    }
    p a:hover {
      text-decoration: underline;
    }
  }
}
