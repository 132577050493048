@use "../../mixin.scss" as *;
@use "../../variable.scss" as *;

.how-it-works {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 86px 0 101px;
  .container1286 {
    box-sizing: border-box;
  }
  @include media991() {
    padding: 33px 0 35px;
  }
  &__ring {
    position: absolute;
    right: -60px;
    top: 43px;
    transform: rotate(180deg);
    max-width: 247px;
    height: auto;
    @include media575() {
      right: -120px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__details {
    text-align: center;
    h1 {
      font-size: 40px;
      font-weight: 400;
      text-transform: capitalize;
      line-height: 54px;
      margin-bottom: 25px;
      @include media991() {
        font-size: 22px;

        margin-bottom: 0;
      }
    }
    p {
      font-size: 20px;
      @include media991() {
        font-size: 16px;
      }
    }
  }
  &__steps {
    display: flex;
    justify-content: space-between;

    @include media991() {
      flex-direction: column;
    }
    &-one {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 53px;
      @include media991() {
        margin: 30px 0 0 0;
      }
      &:nth-child(2) {
        margin: 53px 83px 0 83px;
        @include media991() {
          margin: 30px 0 0 0;
        }
      }
      &-round {
        min-width: 220px;
        height: 220px;
        background: $background-color;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        @include media575() {
          min-width: 170px;
          height: 170px;
        }
        .digit {
          position: absolute;
          bottom: -18px;
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          font-weight: bold;
          border-radius: 100%;
          color: $light-color;
          background: linear-gradient(270deg, #1b75bb 0%, #08a972 100%);
        }
      }

      &-details {
        text-align: center;

        h2 {
          font-size: 25px;
          margin: 30px 0 15px;
          text-transform: capitalize;
          @include media575() {
            font-size: 18px;
          }
        }
        p {
          @include media575() {
            font-size: 14px;
          }
        }
      }
    }
  }
}
