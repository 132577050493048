@use "../../mixin" as *;
@use "../../variable" as *;

.deserve {
  background: linear-gradient(120.58deg, #1b75bb 10.52%, #08a972 68.57%);
  color: #ffffff;
  display: flex;
  justify-content: center;

  .container1500 {
    padding: 60px 131px 60px 127px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media1300 {
      padding: 20px;
    }

    @include media1500 {
      width: 100%;
    }

    @include media991 {
      flex-direction: column-reverse;
    }
  }

  &-left {
    max-width: 509px;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;

    @include media991 {
      margin-top: 14px;
    }

    h2 {
      font-size: 40px;
      font-weight: 700;
      line-height: 1.2;
      text-transform: capitalize;

      @include media991 {
        font-size: 22px;
        margin-top: 8px;
      }

      span {
        font-weight: 400;
      }
    }

    .desc {
      font-size: 20px;
      font-weight: 400;
      line-height: 1.4;
      margin-bottom: 20px;
      margin-top: 10px;

      @include media991 {
        font-size: 16px;
        margin-bottom: 20px;
        margin-top: 20px;
      }
    }
    .deservestarRating {
      gap: 10px !important;
    }
  }

  .banner__details-rating {
    margin-top: 20px;
  }
  &-right {
    max-width: 671px;
    width: 100%;
    margin-left: 63px;
    position: relative;
    aspect-ratio: 1/0.8;
    @include media991 {
      margin-left: 0px;
    }

    .comRightImg {
      object-fit: cover;
      width: 100%;
      max-height: 545px;
      height: 100%;
      border-radius: 30px;
    }
  }
}
